///
/// Mixins & Extendables
///
/// These are the generic typography styles across the site.
/// Each style is both a mixin and a class.
/// Apply the class directly to the template mark up,
//  use the mixin version when working inside a media query.
///
/// Example:
/// .store-locator__header {
///   @include headline--secondary;
///   @media #{$medium-up} {
///     @include headline--primary;
///   }
/// }
///

@mixin brandon-text {
  font-family: $body-font;
}

@mixin brandon-text-light {
  font-family: $body-font-light;
}

@mixin brandon-text-medium {
  font-family: $body-font;
}

@mixin brandon-text-bold {
  font-family: $body-font-bold;
}

@mixin bb-sans {
  font-family: $bb-sans;
}

@mixin bbtext-regular {
  font-family: $bbtext-regular;
  font-weight: 400;
  font-style: normal;
}

@mixin bbtext-regular-italic {
  font-family: $bbtext-regular-itaic;
  font-weight: 400;
  font-style: italic;
}

@mixin bbtext-bold {
  font-family: $bbtext-bold;
  font-weight: 600;
  font-style: normal;
}

@mixin bbtext-bold-italic {
  font-family: $bbtext-bold-itaic;
  font-weight: 600;
  font-style: italic;
}

@mixin bbtext-light {
  font-family: $bbtext-light;
  font-weight: 200;
  font-style: normal;
}

@mixin bbtext-light-italic {
  font-family: $bbtext-light-itaic;
  font-weight: 200;
  font-style: italic;
}

// hx mixin for @include when using breakpoints
// .hx when using on markup, do not @extend

// h1
@mixin h1 {
  text-transform: uppercase;
  margin: 0 0 17px 0;
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 24px;
    line-height: get-line-height(24px, 27px);
    letter-spacing: 0.24em;
    @include breakpoint($medium-up) {
      font-size: 39px;
      line-height: get-line-height(39px, 47px);
    }
  } @else {
    @include typography-headline--l;
  }
}

// h2
@mixin h2 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 37px;
    line-height: get-line-height(37px, 46px);
    letter-spacing: 0.04em;
  } @else {
    @include typography-headline--m;
  }
}

// h3
@mixin h3 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 21px;
    line-height: get-line-height(21px, 28px);
    letter-spacing: 0.04em;
    @include breakpoint($medium-up) {
      font-size: 28px;
      line-height: get-line-height(28px, 34px);
      letter-spacing: 0.034em;
    }
  } @else {
    @include typography-body-text;
  }
}

// h4
@mixin h4 {
  text-transform: uppercase;
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 19px;
    line-height: get-line-height(19px, 27px);
    letter-spacing: 0.24em;
    @include breakpoint($medium-up) {
      font-size: 25px;
      line-height: get-line-height(25px, 30px);
    }
  } @else {
    @include typography-body-text;
  }
}

// h5
@mixin h5 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 14px;
    line-height: get-line-height(14px, 34px);
    letter-spacing: 0.01em;
    color: $color-black;
    @include breakpoint($medium-up) {
      font-family: $body-font;
      font-size: 22px;
      line-height: 1;
      letter-spacing: 0.02em;
      color: $color-pink;
    }
  } @else {
    @include typography-body-text;
    color: $color-core-black;
  }
}

// h6
@mixin h6 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 18px;
    line-height: get-line-height(18px, 24px);
    letter-spacing: 0.06em;
    @include breakpoint($medium-up) {
      line-height: get-line-height(18px, 25px);
    }
  } @else {
    @include typography-label-text;
  }
}

// h7
@mixin h7 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 18px;
    line-height: get-line-height(18px, 24px);
    letter-spacing: 0.06em;
    @include breakpoint($medium-up) {
      font-size: 16px;
      line-height: get-line-height(16px, 30px);
    }
  } @else {
    @include typography-label-text;
  }
}

// h8
@mixin h8 {
  text-transform: uppercase;
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 12px;
    line-height: get-line-height(12px, 21px);
    letter-spacing: 1px;
    @include breakpoint($medium-up) {
      line-height: get-line-height(14px, 21px);
    }
  } @else {
    @include typography-label-text;
  }
}

// h9
@mixin h9 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 14px;
    line-height: get-line-height(14px, 17px);
    @include breakpoint($medium-up) {
      letter-spacing: 0.06em;
      line-height: 1;
    }
  } @else {
    @include typography-label-text;
  }
}

// h10
@mixin h10 {
  text-transform: uppercase;
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 18px;
    line-height: get-line-height(18px, 31px);
    letter-spacing: 0.24em;
  } @else {
    @include typography-label-text;
  }
}

// h11
@mixin h11 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 21px;
    line-height: get-line-height(21px, 46px);
    letter-spacing: 0.04em;
  } @else {
    @include typography-body-text;
  }
}

// h12
@mixin h12 {
  text-transform: uppercase;
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 11px;
    line-height: get-line-height(11px, 19px);
    letter-spacing: 0.22em;
  } @else {
    @include typography-label-text;
  }
}

// h13
@mixin h13 {
  position: relative;
  display: inline-block;
  margin: 0 auto 30px auto;
  padding: 0 33px;
  text-align: center;
  text-transform: uppercase;
  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 2px;
    top: 43%;
    left: 0;
  }
  .inner,
  &__inner {
    position: relative;
    display: inline-block;
    padding: 0 7px 0 9px;
    background: $color-white;
  }
  @include breakpoint($medium-up) {
    padding: 0 55px;
  }
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 12px;
    line-height: get-line-height(12px, 15px);
    letter-spacing: 0.2em;
    &:before {
      background: $color-black;
    }
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  } @else {
    @include typography-label-text;
    &:before {
      background: $color-core-black;
    }
  }
}

// h14
@mixin h14 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.01em;
  } @else {
    @include typography-label-text;
  }
}

// h15
@mixin h15 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.01em;
  } @else {
    @include typography-label-text;
  }
}

// h16
@mixin h16 {
  text-transform: uppercase;
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 14px;
    line-height: get-line-height(14px, 17px);
    letter-spacing: 0.24em;
  } @else {
    @include typography-label-text;
  }
}

// h17
@mixin h17 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.01em;
  } @else {
    @include typography-label-text;
  }
}

// h18
@mixin h18 {
  text-transform: uppercase;
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 11px;
    line-height: get-line-height(11px, 13px);
    letter-spacing: 0.24em;
  } @else {
    @include typography-label-text;
  }
}

// h19
@mixin h19 {
  text-transform: uppercase;
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 21px;
    letter-spacing: 0.24em;
  } @else {
    @include typography-body-text;
  }
}

// h20
@mixin h20 {
  text-transform: uppercase;
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 11px;
    line-height: get-line-height(11px, 50px);
    letter-spacing: 0.24em;
  } @else {
    @include typography-label-text;
  }
}

// h21
@mixin h21 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 12px;
    line-height: get-line-height(12px, 24px);
    letter-spacing: 0.01em;
  } @else {
    @include typography-label-text;
  }
}

// h22
@mixin h22 {
  text-transform: uppercase;
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 9px;
    line-height: get-line-height(9px, 12px);
    letter-spacing: 0.2em;
  } @else {
    @include typography-label-text;
  }
}

// h23
@mixin h23 {
  @if $cr22 == false {
    font-family: $body-font;
    font-weight: normal;
    font-size: 9px;
    line-height: get-line-height(9px, 15px);
    letter-spacing: 0.01em;
  } @else {
    @include typography-label-text;
  }
}

// h24
@mixin h24 {
  text-transform: uppercase;
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 14px;
    line-height: get-line-height(14px, 18px);
    letter-spacing: 0.2em;
  } @else {
    @include typography-label-text;
  }
}

// h25
@mixin h25 {
  text-transform: uppercase;
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 16px;
    line-height: get-line-height(16px, 22px);
    letter-spacing: 0.2em;
  } @else {
    @include typography-label-text;
  }
}

// Headline 1
@mixin t1 {
  @if $cr22 == false {
    font-family: $bb-sans;
    line-height: 1;
    font-size: 80px; // ~.53
    @include breakpoint($medium-up) {
      font-size: 90px;
    }
    @include breakpoint($landscape-up) {
      font-size: 150px;
    }
  } @else {
    @include typography-headline--xl;
  }
}

// Headline 2
@mixin t2 {
  @if $cr22 == false {
    font-family: $bb-sans;
    line-height: 0.95;
    letter-spacing: 1.5px;
    font-size: 48px; // ~.53
    @include breakpoint($medium-up) {
      font-size: 68px; // ~.75
    }
    @include breakpoint($landscape-up) {
      font-size: 90px;
    }
  } @else {
    @include typography-headline--l;
  }
}

// Headline 3
@mixin t3 {
  @if $cr22 == false {
    font-family: $bb-sans;
    line-height: 1;
    font-size: 22px;
    @include breakpoint($medium-up) {
      font-size: 30px;
    }
    @include breakpoint($landscape-up) {
      font-size: 40px;
    }
  } @else {
    @include typography-headline--m;
  }
}

// Headline 3
@mixin t4 {
  @if $cr22 == false {
    font-family: $bb-sans;
    line-height: 1;
    font-size: 34px;
    @include breakpoint($medium-up) {
      font-size: 48px;
    }
    @include breakpoint($landscape-up) {
      font-size: 64px;
    }
  } @else {
    @include typography-headline--l;
  }
}

// Headline 3
@mixin t5 {
  @if $cr22 == false {
    font-family: $bb-sans;
    line-height: 1;
    font-size: 58px;
    @include breakpoint($medium-up) {
      font-size: 82px;
    }
    @include breakpoint($medium-up) {
      font-size: 110px;
    }
  } @else {
    @include typography-headline--xl;
  }
}

// Body Text
@mixin body-text {
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-size: 15px;
    line-height: 1;
    letter-spacing: 1.5px;
  } @else {
    @include typography-body-text;
  }
}

// 2019 Redesign - SPP mixins till styleguide is completed
@mixin spp_label {
  font-family: $body-font-bold;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
}

// 2022 Redesign - CR22
// Typography Headline XL
@mixin typography-headline--xl {
  font-family: $bb-sans;
  letter-spacing: $typography-headline--xl-letter-spacing;
  font-size: $typography-headline--xl-font-size-mobile;
  line-height: $typography-headline--xl-line-height-mobile;
  @include breakpoint($medium-up) {
    font-size: $typography-headline--xl-font-size-desktop;
    line-height: $typography-headline--xl-line-height-desktop;
  }
}

// Typography Headline L
@mixin typography-headline--l {
  font-family: $bb-sans;
  letter-spacing: $typography-headline--l-letter-spacing;
  font-size: $typography-headline--l-font-size-mobile;
  line-height: $typography-headline--l-line-height-mobile;
  @include breakpoint($medium-up) {
    font-size: $typography-headline--l-font-size-desktop;
    line-height: $typography-headline--l-line-height-desktop;
  }
}

// Typography Headline M
@mixin typography-headline--m {
  font-family: $bbtext-regular;
  letter-spacing: $typography-headline--m-letter-spacing;
  font-size: $typography-headline--m-font-size-mobile;
  line-height: $typography-headline--m-line-height-mobile;
  @include breakpoint($medium-up) {
    font-size: $typography-headline--m-font-size-desktop;
    line-height: $typography-headline--m-line-height-desktop;
  }
}

// Typography Body Text
@mixin typography-body-text {
  font-family: $bbtext-regular;
  letter-spacing: $typography-body-text-letter-spacing;
  font-size: $typography-body-text-font-size;
  line-height: $typography-body-text-line-height;
}

// Typography Link Text
@mixin typography-link-text {
  font-family: $bbtext-regular;
  letter-spacing: $typography-link-text-letter-spacing;
  font-size: $typography-link-text-font-size;
  line-height: $typography-link-text-line-height;
}

// Typography Label Text
@mixin typography-label-text {
  font-family: $bbtext-regular;
  letter-spacing: $typography-label-text-letter-spacing;
  font-size: $typography-label-text-font-size;
  line-height: $typography-label-text-line-height;
}

// Typography Annotation Text
@mixin typography-annotation-text {
  font-family: $bbtext-regular;
  letter-spacing: $typography-annotation-text-letter-spacing;
  font-size: $typography-annotation-text-font-size;
  line-height: $typography-annotation-text-line-height;
}

// SPP Label Text
@mixin typography-spp-label-text {
  font-family: $bbtext-regular;
  font-size: 12px;
  line-height: get-line-height(12px, 15.6px);
}
