@mixin button {
  @include font-smoothing(antialiased);
  @include transition(color 0.1s linear, background 0.1s linear);
  display: inline-block;
  text-decoration: none;
  text-align: center;
  color: $color-white;
  cursor: pointer;
  &:hover {
    color: $color-white;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  @if $cr22 == false {
    @include swap_direction(padding, 0.75em 1em);
    font-family: $body-font-bold;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 1px;
    background-color: $base-button-color;
    &:hover {
      background-color: $color-cta-hover;
    }
  } @else {
    @include typography-link-text;
    background-color: $color-core-black;
    text-transform: capitalize;
    padding: 20px 30px;
    &:hover {
      background-color: $color-black;
    }
  }
}

@mixin button--white {
  @include button;
  background: $color-white;
  @if $cr22 == false {
    color: $color-black;
    border: 1px solid $color-black;
    &:hover {
      background-color: $color-cta-hover;
      color: $color-white;
    }
  } @else {
    color: $color-core-black;
    border: 1px solid $color-core-black;
    &:hover {
      background-color: $color-white;
      color: $color-core-black;
    }
  }
}

@mixin button--disabled {
  cursor: default;
  @if $cr22 == false {
    background-color: $color-light-gray;
    &:hover,
    &:active {
      background-color: $color-light-gray;
    }
  } @else {
    background-color: $color-gray-on-light;
    &:hover,
    &:active {
      background: $color-gray-on-light;
    }
  }
}

@mixin button--inverted {
  @include button;
  background: $color-white;
  &:hover {
    color: $color-white;
  }
  @if $cr22 == false {
    color: $color-black;
    &:hover {
      background-color: $color-cta-hover;
    }
  } @else {
    color: $color-core-black;
    &:hover {
      background-color: $color-gray-on-light;
    }
  }
}

@mixin button--secondary {
  @include button;
  color: $color-white;
  &:hover {
    color: $color-white;
  }
  @if $cr22 == false {
    background-color: $color-pink;
    &:hover {
      background-color: $color-pink;
    }
  } @else {
    background-color: $color-gray-on-light;
    &:hover {
      background-color: $color-gray-on-light;
    }
  }
}

@mixin input-button--secondary {
  color: $color-white;
  &:hover {
    color: $color-white;
  }
  @if $cr22 == false {
    background-color: $color-pink;
    &:hover {
      background-color: $color-pink;
    }
  } @else {
    background-color: $color-black;
    &:hover {
      background-color: $color-black;
    }
  }
}

@mixin button--outline {
  @include button;
  background: $color-white;
  &:hover {
    background: $color-white;
  }
  @if $cr22 == false {
    border: 2px solid $color-pink;
    color: $color-pink;
    &:hover {
      border-color: $color-black;
      color: $color-black;
    }
  } @else {
    border: 2px solid $color-gray-on-light;
    color: $color-gray-on-light;
    &:hover {
      background: $color-white;
    }
  }
}

@mixin button--outline-thin {
  @include button--outline;
  font-size: 11px;
  @if $cr22 == false {
    border: 1px solid $color-pink;
  } @else {
    border: 1px solid $color-gray-on-light;
  }
}

@mixin link-reset {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  @if $cr22 == false {
    color: $color-black;
  } @else {
    color: $color-core-black;
  }
}
%link-reset {
  @include link-reset;
}

%l1-l2-base {
  font-family: $body-font-bold;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

@mixin link($color-active, $color-hover, $touch: true) {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  vertical-align: top;
  padding-bottom: 5px;
  color: $color-active;
  border-bottom: 2px solid $color-active;
  text-transform: uppercase;
  &:hover {
    color: $color-hover;
    border-color: $color-hover;
    text-decoration: none;
  }
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-size: 14px;
    line-height: get-line-height(14px, 19px);
    letter-spacing: 0.24em;
  } @else {
    @include typography-link-text;
  }
  @if $touch {
    .touch &,
    .touchevents & {
      @include swap_direction(padding, 10px);
      border-style: solid;
      border-width: 2px;
      text-decoration: none;
    }
  }
}

@mixin text-link-1 {
  transition: color 0.2s;
  @if $cr22 == false {
    color: $color-black;
    &:hover {
      color: $color-pink;
    }
  } @else {
    @include typography-link-text;
    color: $color-core-black;
    &:hover {
      color: $color-gray-on-light;
    }
  }
}

@mixin text-link-2 {
  transition: color 0.2s;
  @if $cr22 == false {
    color: $color-pink;
    &:hover {
      color: $color-black;
    }
  } @else {
    @include typography-link-text;
    color: $color-gray-on-light;
    &:hover {
      color: $color-core-black;
    }
  }
}

@mixin text-link-3 {
  display: inline-block;
  vertical-align: top;
  padding-bottom: 2px;
  color: $color-white;
  border-bottom: 2px solid $color-white;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: $color-white;
    border-color: $color-white;
    text-decoration: none;
  }
  @if $cr22 == false {
    font-family: $body-font-bold;
    font-size: 17px;
    line-height: get-line-height(17px, 19px);
    letter-spacing: 0.08em;
  } @else {
    @include typography-link-text;
  }
}

///
/// Link overrides
/// Mostly used for touch devices, but also available for desktop
///
%link--outline {
  border-style: solid;
  border-width: 2px;
  @include swap_direction(padding, 10px);
  text-decoration: none;
}

%link--underline {
  border-top: none;
  border-#{$rdirection}: none;
  border-bottom: 2px solid;
  border-#{$ldirection}: none;
  @include swap_direction(padding, 0 0 5px 0);
}

@mixin link--regular {
  text-transform: none;
  letter-spacing: normal;
  font-family: $body-font;
  border-bottom: 1px solid #000000;
}

@mixin link--inverted {
  &:hover {
    color: $color-black !important;
    border-color: $color-black !important;
  }
  @if $cr22 == false {
    color: $color-pink;
    border-color: $color-pink;
  } @else {
    color: $color-gray-on-light;
    border-color: $color-gray-on-light;
  }
}

@mixin link--light {
  color: $color-white;
  border-color: $color-white;
  @if $cr22 == false {
    &:hover {
      color: $color-pink;
      border-color: $color-pink;
    }
  } @else {
    &:hover {
      color: $color-gray-on-light;
      border-color: $color-gray-on-light;
    }
  }
}

@mixin l1 {
  @if $cr22 == false {
    @include link($color-black, $color-pink);
  } @else {
    @include link($color-black, $color-gray-on-light);
  }
}

@mixin l2 {
  @if $cr22 == false {
    @include link($color-pink, $color-pink);
  } @else {
    @include link($color-gray-on-light, $color-gray-on-light);
  }
}

@mixin l3 {
  color: $color-black;
}

@mixin l4 {
  @include transition(
    color 0.2s ease-in-out,
    border 0.2s ease-in-out,
    background-color 0.2s ease-in-out
  );
  color: $color-black;
  text-decoration: none;
  &:hover {
    color: $hover-link-color;
  }
  &:active,
  &:focus {
    outline: none;
  }
}

// used in accounts
@mixin l5 {
  font-family: $body-font-bold;
  font-size: 11px;
  line-height: 25px;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  @if $cr22 == false {
    color: $color-pink;
    border-bottom: 1px solid $color-pink;
  } @else {
    color: $color-gray-on-light;
    border-bottom: 1px solid $color-gray-on-light;
  }
}

// used in dropdown
@mixin l6 {
  font-family: $body-font;
  font-size: 14px;
  color: $color-black;
  line-height: 18px;
  text-decoration: none;
  border-bottom: 1px solid $color-black;
  &:hover {
    text-decoration: none;
  }
}

// 2022 Redesign - CR22
@mixin cta-button {
  @include typography-link-text;
  cursor: pointer;
  display: inline-block;
  padding: 20px 30px;
  text-transform: none;
}

@mixin cta-primary {
  @include cta-button;
  text-decoration: none;
  color: $color-white;
  background: $color-core-black;
  &:hover {
    background: $color-black;
  }
}

@mixin cta-primary-white {
  @include cta-button;
  text-decoration: none;
  color: $color-core-black;
  background: $color-white;
}

@mixin cta-primary-gray {
  @include cta-button;
  text-decoration: none;
  color: $color-white;
  background: $color-gray-on-light;
}

@mixin cta-secondary {
  @include cta-button;
  text-decoration: none;
  border: 1px solid $color-gray-on-light;
  box-sizing: border-box;
  color: $color-core-black;
}

@mixin cta-text {
  @include typography-link-text;
  cursor: pointer;
  text-decoration-line: underline;
  color: $color-core-black;
}

@mixin cta-text-gray {
  @include typography-link-text;
  cursor: pointer;
  text-decoration-line: underline;
  color: $color-gray-on-light;
}

@mixin dropdown-shades {
  @include typography-link-text;
  border: none;
  &:hover {
    color: $color-black;
  }
}
