///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
///

// Layout
$max-width: 1024px;
$max-width-medium: 1280px;
$max-width-large: 1440px;
$max-width-xlarge: 1920px;
$mobile: 320px;
$mobileMedium: 375px;
$desktopLarge: 1500px;
$tablet: 768px;
$desktop: 1024px;

$global-header-height: 165px;
$mobile-header-1-height: 52px; // Just the utility nav
$desktop-sticky-header-height: 54px;

$nav-padding: 30px;
$nav-padding-breakpoints: $medium-up;
$nav-xlarge-padding-breakpoints: ($landscape-up, $max-width-xlarge + $nav-padding);
$nav-label-font-size: 20px;
$nav-icon-width: 20px;
$nav-icon-height: 20px;

// Media queries
// global/_media-queries.scss
$small-range: (0, 640px); // iPhone: $small-up, $small-only
$medium-range: (641px, 768px); // iPad portrait: $medium-up, $medium-only
$large-range: (769px, 1024px); // iPad landscape: $large-up, $large-only
$xlarge-range: (1025px, 1279px); // Desktop interim: $xlarge-up, $xlarge-only
$xxlarge-range: (1280px); // Everything above: $xxlarge-up
$wide-short-range: (2, 1); // Short-wide aspect: $wide-short
$front-tall-range: (760px); // minimum height for front page tall nav
$nav-tall-range: (640px); // minimum height for nav touts
$product-normal-range: (641px, 1279px); // Desktop interim: $product-normal-up, $product-normal-only
$product-wide: (1280px); // Desktop interim: $product-wide-up
$xxxlarge-range: (1367px); //Everything above: $xxlarge-range

// mobile specific adjustments
$iphone-6-up: $mobileMedium;
$iphone-portrait-only: max-width $mobileMedium !default; // iphone 6 portrait
$iphone-6plus-only: max-width 736px !default;
$iphone-6-only: max-width 414px !default;
$screen: 'only screen' !default;
$landscape: '#{$screen} and (orientation: landscape)' !default;
$portrait: '#{$screen} and (orientation: portrait)' !default;
$device-tablet-android: '#{$screen} and (min-width: 800px) and (max-width: 1280px)' !default; // large android tablets

// Typography
$brandon-text: 'Brandon Text' !default;
$brandon-text-regular: $brandon-text !default;
$brandon-text-light: 'Brandon Text Light' !default;
$brandon-text-medium: 'Brandon Text Medium' !default;
$brandon-text-bold: 'Brandon Text Bold' !default;
$header-font-family: $brandon-text-bold !default;
$lato-black-bold: 'Lato Black' !default;
$lato-regular: 'Lato Regular' !default;
$roboto-text: 'roboto-mono_regular', Helvetica, Arial, Sans-Serif !default;
$bb-sans: 'BBSans Regular', Helvetica, Arial, sans-serif !default;

// Fonts - BBText
$bbtext-regular: 'BBText Regular', Helvetica, Arial, sans-serif !default;
$bbtext-regular-itaic: 'BBText Regular Italic', Helvetica, Arial, sans-serif !default;
$bbtext-bold: 'BBText Bold', Helvetica, Arial, sans-serif !default;
$bbtext-bold-itaic: 'BBText Bold Italic', Helvetica, Arial, sans-serif !default;
$bbtext-light: 'BBText Light', Helvetica, Arial, sans-serif !default;
$bbtext-light-itaic: 'BBText Light Italic', Helvetica, Arial, sans-serif !default;

$font--helvetica-neue: 'Helvetica Neue', Arial, sans-serif !default;

$base-font-family: $bbtext-regular !default;

// Style font updates.
$font_updates: false !default;

$body-font: $brandon-text;

@if $font_updates == true {
  $body-font: $bbtext-regular;
}

$body-font-bold: $brandon-text-bold;

@if $font_updates == true {
  $body-font-bold: $bbtext-bold;
}

$body-font-light: $brandon-text-light;

@if $font_updates == true {
  $body-font-light: $bbtext-light;
}

// Spoiler
$spoiler: 'spoiler' !default;
$spoiler-bold: 'spoiler-bold' !default;
$spoiler-light: 'spoiler-light' !default;
$spoiler-regular: 'spoiler-regular' !default;
$spoiler-black: 'spoiler-black' !default;

// Miller
$miller-banner-roman: 'MillerBanner Roman' !default;
$miller-banner-light: 'MillerBanner Light' !default;

// Sizes
// same unit is required for calculation: $base-leader: ($base-line-height - $base-font-size) * $font-unit / $base-font-size;
// on line 36 of themes/bb_base/js/lib/compass-mixins/lib/compass/typography/_vertical_rhythm.scss.
$base-font-size: 14px;
$base-line-height: 1.45 * 14px;

// Colors (http://sachagreif.com/sass-color-variables/)
$color-black: #000000;
$color-black-transparent: rgba(0, 0, 0, 0.75);

$color-gray: #d0d0d0;
$color-gray-background: #eff0f0;
$color-gray-border: #bbbbb9;
$color-gray-border-dimmer: #646464;
$color-gray-tooltip: #b7b7b8;
$color-gray-light: #d4d4d4;
$color-gray-lighter: #ddd;
$color-gray-dark: #929292;
$color-gray-alt: #d1d2d1;
$color-gray-light-alt: #eff0f0;
$color-gray-dim: #696969;
$color-gray-fb: #555;
$color-gray-link: #949494;
$color-gray-bg: #f5f5f5;
$color-gray-darker: #666;

$color-bundle-pink: #e55c79;
$color-bundle-gray: #757575;
$color-nobel: #999999;
$color-pink-swan: #b3b3b3;
$color-red: #ff0000;
$color-white: #ffffff;
$color-white-op0: rgba(255, 255, 255, 0);
$color-white-op15: rgba(255, 255, 255, 0.15);
$color-white-op70: rgba(255, 255, 255, 0.7);
$color-white-op95: rgba(255, 255, 255, 0.95);
$color-white-transparent: rgba(255, 255, 255, 0.3);
$color-pink: #ff4661;
$color-pink-red: #ef4963;
$color-light-pink: #ec5665;
$color-redder-pink: #ff3463;
// $color-light-brown: #f7f4ef;
$color-light-brown: #f0f0f0; // This is gray now, they used brown before for everything
$color-beige-dark: #afadaa;
$color-beige-background: #f8f4f1;
$color-nude: #f3efea;
$color-beige: $color-nude; //$color-beige: #F1EEE9; very close values
$color-light-gray: #cccccc;
$color-lightest-gray: #e2e2e2;
$color-border-gray: #959595;
$color-grey-footer: #888888;
$color-facebook: #3b5998;
$color-vkontakte: #4a76a8;
$color-twitter: #42a8df;
$color-linkedin: #007bb6;
$color-instagram: #517fa4;
$color-pinterest: #cb2027;
$color-border-lighter-gray: #dadada;
$color-google-plus: #d34836;
$color-tumblr: #35465c;
$color-youtube: #cd201f;
$color-pink-shade: #ff526c;
$color-green: #05ba59;
$color-darker-green: #009f07;
$color-orange: #edba1e;
$color-lighter-gray: #e6e6e6;
$color-pink-shade: #fc4d67;
$color-mint: #e3f5f1;
$color-dark-navy: #040a2b;
$color-matterhorn-shade: #535353;
$color-dark-red: #930808;
$color-nero-shade: #252323;
$color-whisper-shade: #eee;
$color-red-shade: #dd0000;
$color-alice-blue-shade: #f2f3f4;
$color-crushed-angel: #df747b;
$color-facemakers-beige: #fff4e9;
$color-facemakers-grey: #f6f4f4;
$color-dull-orange: #ba9563;
$color-fantastic: #e7e5df;
$color-enthusiast: #bea652;
$color-loyalist: $color-black;
$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;
$color-light-orange: #fef2f2 !default;
$tick: '\2713';
$cross: '\2715';
$color--success: #458745 !default;
$color-silver: #c0c0c0;
$color-grey-shadow: rgba(0, 0, 0, 0.54);
$color-grey-dark: #1a1a1a;
$color-grey-light: #eff1f0;
$color-grey-border: #c7c7c7;
$color-grey-border-light: #ede9e4;
$color-grey-standard: rgba(153, 153, 153, 0.02);
$color-grey-eclipse: #3f3f3f;
$color-grey-shade: #e0e0e0;
$color-grey-lighter: #fdfdfd;
$color-green-light: #70b000;
$color-white-shade: rgba(0, 0, 0, 0.25);
$color-black-light: #191919;
$color-lightest-orange: #f8f7f5;
$color-sharp-black: #111111;
$color-navy-blue: #0075db;
$color-grey-dark-border: #303030;
$color-grey-bg: #f7f7f7;

// Bobbi V2
$color-reddish-pink: #ff3453;
$color-warm-grey: #777777;
$color-warm-gray: $color-warm-grey;
$color-filter-grey: #fafafa;
$color-filter-gray: $color-filter-grey;
$color-black-2: #1b1b1b;
$color-very-light-pink: #ebebeb;
$color-light-gray2: #e1e1e1;
$color-italic-gray: #727272;
$color-tier1: #762a00;
$color-tier2: #616161;
$color-tier3: #ab7d00;

// Bobbi V2 - colors from Pon
$color-cta-hover: #333333;
$color-light-border: #e7e7e7;

// One-Click Checkout Error Message
$color-maroon: #b00020;

$colors: (
  color-black: $color-black,
  color-white: $color-white,
  color-light-brown: $color-light-brown,
  color-light-gray: $color-light-gray,
  color-pink: $color-pink,
  color-google-plus: $color-google-plus,
  color-pinterest: $color-pinterest,
  color-youtube: $color-youtube,
  color-twitter: $color-twitter,
  color-linkedin: $color-linkedin,
  color-facebook: $color-facebook,
  color-instagram: $color-instagram,
  color-tumblr: $color-tumblr
);

// System colors
$color-focus: #2a6496 !default;

// Background Color
$base-background-color: $color-white;

// Font Colors
$base-font-color: $color-black;
$base-accent-color: $color-pink;

// Link Colors
$base-link-color: $color-pink;
$hover-link-color: $color-pink;
$base-button-color: $color-black;
$hover-button-color: $hover-link-color;

// Border color
$base-border-color: $color-light-gray;

// Sprites
$sprites-sprite-dimensions: true;

// If you have questions about why these aren't classes
// (http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/)
// It's because that method triggers compatability mode:
// https://github.com/h5bp/html5-boilerplate/issues/1187
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
$ie11: "html[data-useragent*='MSIE 11.0']";

// Compass variables
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

// LBO configuration
// Don't load LBO styles for all locales by default
$lbo-enabled: false !default;

// Order Groove configuration
$og-enabled: false !default;

//Signin Page redesign configuration;
$signin_updated: false !default;

// Signin / signup LUX updates.
$sign-in-up_update: false !default;

$left: left;
$right: right;
$arrow_rdirection: arrow_right !default;

$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;
$cart-spacing: 10px; // common shared margin/paddings

$content-block-gradient-start: rgba(#857b38, 0);
$content-block-gradient-end: #2d2520;
$header-border-color: #1d1d1d;
$header-mobile-border-color: #f6f6f6;

// account vars
$account-indent: 20px;
$account-separator: 1px solid $color-gray-border;
$account-alt-heading-separator: 1px solid $color-black;

//checkout vars
$sample-page: '.samples-page';
$cart-line: 1px solid $color-gray-border; // line used everywhere
$cart-container-width: $max-width;
// cart item in checkout-cart-item
$cart-item-product-width: 16%;
$cart-item-container-width: 100% - $cart-item-product-width;
$cart-item-desc-width: 38%;
$checkout-sidebar-width: percentage(379px / $cart-container-width);
$checkout-spacing: 20px; // also common
//$cart-sidebar-width: percentage(218px/$cart-container-width); // this will override
$cart-sidebar-width: $checkout-sidebar-width; //same as onepage
$mobile-outer-gutter: 25px;
$checkout-pushdown: 43px; // used to nudge content down from top

// Scrollbar
$color-scrollthumb: rgba(84, 84, 84, 0.5);

// Appointment Booking Reskin 2019 vars
$ab_skin_v2: false !default;
$appt-book-color-gray-field: #575757;
$appt-book-color-gray-border: #b7b7b7;
$appt-book-color-gray-disabled: #bababa;
$ab-padding: 30px;
$ab-padding-breakpoints: $medium-up;
$ab-xlarge-padding-breakpoints: ($landscape-up, $max-width-xlarge + $ab-padding);

// Unicode ranges
$unicode-range-hebrew: U+0590-05FF;
$unicode-range-latin: U+0020-024F;
$unicode-range-numerals-symbols: U+0020-0040;
$unicode-currency-shekel: U+20AA;

// Loyalty redesign 2021
$lr2021: false !default;

$lr2021-tier-one-bg-color: #e7c0a7;
$lr2021-tier-one-border-color: #754131;

$lr2021-tier-two-bg-color: #ef374f;
$lr2021-tier-two-border-color: #ea728d;

$lr2021-tier-three-bg-image: #fff1c6;
$lr2021-tier-three-bg-gradient-color-1: #755539;
$lr2021-tier-three-bg-gradient-color-2: #f9e0c3;
$lr2021-tier-three-border-color: #fff1c6;
$lr2021-tier-txt-color: $color-black;
$lr2021-user-status-layout-dynamic: false !default;

// 2022 Redesign - CR22
// Core colors
$color-core-black: #221f20 !default;
$color-core-gold: #d6b371 !default;
$color-core-light-gray: #afb4bb !default;
$color-core-dark-gray: #a9a9a9 !default;
$color-core-red: #c8323e !default;
$color-core-dark-red: #f40000 !default;
// Typography
$color-gray-op60: rgba(34, 31, 32, 0.6) !default;
$color-gray-op65: rgba(34, 31, 32, 0.65) !default;
$color-gray-op10: rgba(34, 31, 32, 0.1) !default;
$color-gray-op15: rgba(34, 31, 32, 0.15) !default;
$color-gray-op20: rgba(34, 31, 32, 0.2) !default;
$color-gray-op40: rgba(34, 31, 32, 0.4) !default;
$color-gray-op50: rgba(34, 31, 32, 0.5) !default;
$color-white-op50: rgba(255, 255, 255, 0.5) !default;
$color-white-op40: rgba(255, 255, 255, 0.4) !default;
$color-white-op20: rgba(255, 255, 255, 0.2) !default;
$color-white-op10: rgba(255, 255, 255, 0.1) !default;
$color-black-op40: rgba(0, 0, 0, 0.4) !default;
$color-white-gray-op50: rgba(233, 233, 233, 0.5) !default;
$color-gray-on-light: #6f6d6e !default;
$color-metal-gray: #898a8d !default;
$color-dark-gray: #101010 !default;
$color-dark-gray-op10: rgba(16, 16, 16, 0.1) !default;
$color-line: #e9e9e9 !default;
$color-light-beige: #f8f6f5 !default;
$color-glossy-gray: #f6f6f6 !default;
$color-glossy-light-gray: #f1f1f1 !default;
$color-black-op5: rgba(0, 0, 0, 0.05) !default;
// Typography Headline XL
$typography-headline--xl-letter-spacing: -0.1em !default;
$typography-headline--xl-font-size-mobile: 46px !default;
$typography-headline--xl-font-size-desktop: 60px !default;
$typography-headline--xl-line-height-mobile: 0.85 !default;
$typography-headline--xl-line-height-desktop: 1 !default;
// Typography Headline L
$typography-headline--l-letter-spacing: -0.1em !default;
$typography-headline--l-font-size-mobile: 36px !default;
$typography-headline--l-font-size-desktop: 40px !default;
$typography-headline--l-line-height-mobile: 0.85 !default;
$typography-headline--l-line-height-desktop: 1 !default;
// Typography Headline M
$typography-headline--m-letter-spacing: -0.02em !default;
$typography-headline--m-font-size-mobile: 22px !default;
$typography-headline--m-font-size-desktop: 24px !default;
$typography-headline--m-line-height-mobile: 1 !default;
$typography-headline--m-line-height-desktop: 1 !default;
// Typography Body Text
$typography-body-text-letter-spacing: 0 !default;
$typography-body-text-font-size: 15px !default;
$typography-body-text-line-height: 1.3 !default;
// Typography Link Text
$typography-link-text-letter-spacing: 0 !default;
$typography-link-text-font-size: 15px !default;
$typography-link-text-line-height: 1.3 !default;
// Typography Label Text
$typography-label-text-letter-spacing: 0 !default;
$typography-label-text-font-size: 15px !default;
$typography-label-text-line-height: 1.3 !default;
// Typography Annotation Text
$typography-annotation-text-letter-spacing: 0 !default;
$typography-annotation-text-font-size: 12px !default;
$typography-annotation-text-line-height: 1.3 !default;
// Category
$color-category-crushed-lip: #dd4955 !default;
$color-category-extra-lip: #dc788e !default;
$color-category-glow: #ebc4c1 !default;
$color-category-foundation-light: #fae7d4 !default;
$color-category-correct-conceal: #eed8c9 !default;
$color-category-finish-powder: #e3c2aa !default;
$color-category-brow: #ae9485 !default;
$color-category-brow-dark: #85614e !default;
$color-category-foundation: #aa7256 !default;
$color-category-foundation-dark: #6e4335 !default;
$color-category-mascara: #202225 !default;
$color-category-skincare: #e3edf8 !default;
$color-category-remedies: #fef4cd !default;

// Fonts: Global Re-use
$font--text: $base-font-family;
$font--heading: $font--text;
$font--subheading: $font--text;

// Max widths for formatters and content containers
$container-max-width-large: 1440px !default;
$container-max-width-xlarge: 1920px !default;

// Imported from Genie: Gnav specific vars
$gnav-max-width: $container-max-width-xlarge;
$gnav-fixed-z-index: 1000 !default;
$navbar-logo-wrap-height: 5vw !default;
$navbar-logo-wrap-mob-height: 10vw !default;
$navbar-main-links-height: 62px;
$navbar-height-default-mob: 84px;
$navbar-height-default: 97px;
$navbar-height-large-logo-mob: calc(
  #{$navbar-main-links-height} + #{$navbar-logo-wrap-mob-height} + 25px
) !default;
$navbar-height-large-logo: calc(
  #{$navbar-height-default} + #{$navbar-logo-wrap-height} + 40px
) !default;
$navbar-height: calc(#{$navbar-logo-wrap-height} + #{$navbar-height-default}) !default;
$navbar-mobile-height: calc(
  #{$navbar-logo-wrap-mob-height} + #{$navbar-main-links-height}
) !default;
$navbar-main-links-gap: 20px;
$offerbar-height: 50px !default;
$offerbar-mobile-height: 48px !default;
$navbar-height-big-logo-offer-mob: calc(#{$navbar-height-large-logo-mob} + #{$offerbar-height});
$navbar-height-big-logo-offer: calc(#{$navbar-height-large-logo} + #{$offerbar-height});
$gnav-mobile-height: $navbar-height-default-mob + $offerbar-mobile-height;
$gnav-height: $navbar-height-default + $offerbar-height;
$gnav-panel-content-pad-top: 40px !default;
$gnav-panel-content-pad-bottom: 20px !default;
$gnav-panel-content-top: $gnav-panel-content-pad-top + $navbar-main-links-height +
  $gnav-panel-content-pad-bottom;
$gnav-panel-content-top-mob: 145px !default;
$toolbar-height: 87px;
$gnav-icon--inline: 14px !default;
$gnav-mobile-hpad--outer: 22px !default;
$gnav-mobile-link-height: 64px !default;
$gnav-content-max-height: 920px !default;
$gnav-first-panel-width: 34% !default;
$gnav-formatter-panel-width: 100% - $gnav-first-panel-width;
$gnav-second-panel-width: 32.4% !default;
$gnav-third-panel-width: 100% - $gnav-second-panel-width !default;
$gnav-search-panel-width: 720px !default;
$header-nav-top-pos: 133px !default;
$width-logo-default: 280px !default;
$width-logo-default-mob: 135px !default;
// Panel animation setting:
$logo-animation-duration: 0s;
$logo-animation-timing-function: ease-out;
$sticky-animation-duration: 0.25s;
$sticky-animation-timing-function: ease;
$gnav-panel-animation-duration: 0.5s;
$gnav-panel-animation-timing-function: ease;
$gnav-second-panel-animation-duration: 0.5s;
$gnav-content-animation-timing-function: ease-in-out;

// Imported from Genie: Content containers outer pad vars
$container-pad-small: 20px !default;
$container-pad-landscape: 40px !default;

// GNAV
$gnav-category-link-letter-spacing: -0.1em !default;

// Redesign 2022
$cr22: false !default;

// Redesign 2024
$cr24: false !default;
$cr24_plp: false !default;

//BB NA UI Cart Updates vars
$cart-wrap-width: 1076px !default;
$cart-wrap-sidebar-width: 360px !default;
$color-black-op86: rgba(0, 0, 0, 0.86) !default;
$color-silver-grey: #bebebe !default;
$color-gray-68: #adadad !default;
$color-dark-shade-gray: #212121 !default;
$color-light-grey: rgba(34, 31, 32, 0.1) !default;
$color-success-background: #70a912 !default;
$color-error-red: #ee0000 !default;

// LUX
$color-facebook-blue-opacity-88: rgba(59, 89, 152, 0.88);
$color-social-terms: rgb(48, 48, 48);
$lux-border-color: rgb(230, 230, 230);
$form-border-color: rgb(78, 78, 78);
$signin-box-lighter-gray: #d6d6d6 !default;
$lux-error: #dc3530 !default;
$color-danube-blue: #5f7cbb !default;
$color-medium-gray-alt: #999999 !default;
$color-navy: #040a2b !default;
$color-green: #07bb5b !default;
$color-btn-grey: #333333 !default;
$color-black-opacity-64: rgba($color-black, 0.64); //#000000A3;
$color-black-opacity-88: rgba($color-black, 0.88); //#000000E0;
$lux-error-opacity-02: rgba($lux_error, 0.02); //#b0002005
$lux-success: #44826e; //rgba(68, 130, 110)
$lux-success-background: #eefbf7; //rgba(238, 251, 247)
$lux-success-background-opacity-60: rgba($lux-success-background, 0.6); //#eefbf799
$lux-success-border: #99dfc7; //rgba(153, 223, 199)
$lux-info-icon: #bb8b60;
$lux-field-label: #757575;
$lux-field-value: #212121;
$lux-submit-button: #f94b6e;
$color-medium-green: #70b000;
$color-grey-text: rgb(204, 204, 204);
$color-blue-dark: rgb(4, 10, 43);
$lux-error-opacity-05: rgba($lux-error, 0.05);
$color-periwinkle-blue: #9eafff;

$filter-shades-block-width: 1920px !default;
$color-cb-gray-extra-light: #f7f7f8 !default;

$color-success-mark-dark: #087443 !default;
$color-error-mark-dark: #a80000 !default;
$color-alert-mark-dark: #b54708 !default;
// Shade Finder Quiz
$shade-finder-quiz-gap-item: 12px !default;
$shade-finder-result-gap-item: 16px !default;

$medium-text-font-size: 20px !default;
$medium-text-line-height: 1.3 !default;
