@import '../theme-bootstrap';

.block--linked {
  cursor: pointer;
}

.content-block {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  &:not(.content-block--no-default-margin) {
    margin: auto;
  }
  overflow: hidden;
  h1,
  h2,
  h3 {
    margin-bottom: auto;
  }
  p {
    line-height: 1.3;
  }
  .button--bare,
  .button--default {
    @include button;
  }
  video {
    max-width: 100%;
  }
  picture img {
    width: 100%;
    z-index: 1;
  }
  &__inner {
    width: 100%;
    height: 100%;
    position: relative;
    @include breakpoint($medium-up) {
      .has-gradient &::before {
        content: ' ';
        background-image: linear-gradient(
          to $ldirection,
          $content-block-gradient-start,
          $content-block-gradient-end
        );
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 2;
      }
    }
  }
  .alignment--bare {
    text-align: center;
  }
  .align-links--bare {
    text-align: center;
  }
  @include breakpoint($small-down) {
    .mobile-align-#{$ldirection} {
      text-align: $ldirection;
    }
    .mobile-align-center {
      text-align: center;
    }
    .mobile-align-#{$rdirection} {
      text-align: $rdirection;
    }
  }
  &__content {
    display: flex;
    width: 100%;
    flex-direction: column;
    @if $cr22 == false {
      height: 96%;
    } @else {
      height: 100%;
    }
    &.vertical-alignment--bare {
      justify-content: center;
    }
    &.horizontal-alignment--bare {
      align-items: center;
    }
  }
  &__content-text {
    width: 100%;
    @if $cr22 == true {
      .content-block__content {
        height: auto;
      }
    }
  }
  &__line {
    word-wrap: break-word;
  }
  &__line--content {
    margin: 1rem auto 0;
    &:first-child {
      margin: 0 auto;
    }
    a:not(.button) {
      text-decoration: none;
      color: $hover-link-color;
      &:hover {
        color: $base-link-color;
      }
    }
  }
  &__content-buttons {
    margin-top: 1rem;
  }
  &__content-button {
    margin-bottom: 15px;
  }
  &__content-link {
    letter-spacing: 1px;
    text-decoration: underline;
    > div {
      cursor: pointer;
    }
  }
  &__media {
    padding: 1rem 0 1.5rem;
  }
  &__responsive-text {
    position: relative;
    z-index: 3;
    padding: 2rem;
    background: $color-white;
  }
  &__picture--badge {
    &.borde-image-circle {
      img {
        border-radius: 50%;
      }
    }
    &.image-small {
      .mantle-media-asset {
        img {
          display: inline-block;
          width: 80px;
          height: 80px;
        }
      }
    }
    &.image-medium {
      .mantle-media-asset {
        img {
          display: inline-block;
          width: 140px;
          height: 140px;
        }
      }
    }
    &.image-large {
      .mantle-media-asset {
        img {
          display: inline-block;
          width: 250px;
          height: 250px;
        }
      }
    }
    &.image-full-square {
      .content-block__picture--badge-wrapper {
        padding-top: 100%;
      }
      .mantle-media-asset {
        position: absolute;
        top: 0;
      }
    }
    .mantle-media-asset {
      img {
        object-fit: cover;
      }
    }
  }
  &--positioned {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
  }
}

.mantle-custom-text {
  p:only-child {
    margin: auto;
  }
}

.content-block--default {
  @include breakpoint($medium-up) {
    .position-image,
    .position--bare {
      @include contentBlockImageHeight;
    }
    .position-text {
      @include contentBlockTextHeight;
    }
  }
}

.content-formatter {
  &--max-width-1024 {
    margin: auto;
    max-width: $max-width;
  }
  &--max-width-1280 {
    margin: auto;
    max-width: $max-width-medium;
  }
  &--max-width-1440 {
    margin: auto;
    max-width: $max-width-large;
  }
  &--max-width-1920 {
    margin: auto;
    max-width: $max-width-xlarge;
  }
  &__text {
    padding-bottom: 2rem;
    z-index: 10;
    position: relative;
    @include breakpoint($medium-up) {
      padding-bottom: 2.5rem;
    }
    .content-block--text {
      overflow: visible;
    }
    .content-block {
      margin: unset;
    }
    &.padding--bare {
      padding: 1rem 2rem;
      @include breakpoint($medium-up) {
        padding: 1rem 3.75rem 3rem;
      }
    }
    &--no-padding {
      padding: 0;
    }
  }
  &__grid {
    @include flexGridColumns;
    .content-formatter__rendered {
      > .content-block {
        height: 100%;
      }
    }
  }
  &__grid-small {
    @include breakpoint($medium-down) {
      @include flexGrid;
    }
  }
  &__grid-large {
    @include breakpoint($medium-up) {
      @include flexGrid;
    }
  }
  &.content-formatter--overlap-style {
    .content-formatter__text {
      padding-bottom: 0;
      .alignment--bare {
        text-align: $ldirection;
      }
      .content-block__line--header {
        margin-bottom: -4.5em;
      }
      .content-block--text {
        overflow: visible;
      }
    }
    .content-block__content-text,
    .content-block__media {
      padding-top: 0;
    }
  }
  .content-formatter__tout {
    padding-top: 0;
    padding-bottom: 0;
    .content-block--mpp-tout {
      &.no-background {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: $color-black;
      }
      .position--bare {
        @include contentBlockTextHeight;
      }
      .default-max-width {
        width: 380px;
        max-width: 90%;
        margin: auto;
      }
      .content-block__content-text.padding--bare {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
  &__content {
    @include breakpoint($small-down) {
      &.padding-sides-small--default {
        padding-#{$ldirection}: 30px;
        padding-#{$rdirection}: 30px;
      }
    }
    @include breakpoint($medium-up) {
      &.padding-sides-large--default {
        padding-#{$ldirection}: 60px;
        padding-#{$rdirection}: 60px;
      }
    }
  }
  &--no-padding & {
    &__text {
      padding: 0;
    }
  }
  .content-formatter__content {
    &.padding-bottom--bare {
      padding-bottom: 2rem;
      @include breakpoint($large-up) {
        padding-bottom: 2.5rem;
      }
    }
    &--gutters {
      .content-formatter__rendered {
        padding: 0;
        @include breakpoint($medium-up) {
          padding: 0 1%;
        }
      }
    }
  }
}

.mpp-header-content-block {
  position: relative;
  &__breadcrumbs {
    position: absolute;
    top: 1rem;
    #{$ldirection}: 2rem;
    z-index: 3;
    color: $color-white;
    a {
      color: $color-white;
      text-decoration: none;
    }
  }
  .content-block--default {
    @include breakpoint($medium-down) {
      .content-block__content-text.padding--bare {
        @if $cr24_plp == true {
          padding: 1.3rem 1.25rem 1.5rem;
        } @else {
          padding-top: 4rem;
        }

      }
      .position--bare {
        @include contentBlockTextHeight;
      }
    }
    @if $cr24_plp == true {
      @include breakpoint($medium-up) {
        .content-block__content-text.padding--bare {
          padding: 5rem 2.5rem 4.5rem;
        }
      }
    }
  }
}

.content-block--basic-tout {
  .content-block--default {
    height: auto;
    .position--bare {
      @include contentBlockTextHeight;
    }
    .content-block__line--header .style--bare {
      @include t3;
    }
  }
}

.content-block--split-width {
  max-width: $max-width-large;
  margin: auto;
  @include breakpoint($medium-down) {
    .max-width {
      max-width: 90%;
      margin: auto;
    }
  }
  .content-block {
    overflow: visible;
  }
  .align-links--bare {
    text-align: center;
    @include breakpoint($large-up) {
      text-align: #{$ldirection};
    }
  }
  @include breakpoint($small-down) {
    .mobile-align-#{$ldirection} {
      text-align: $ldirection;
    }
    .mobile-align-center {
      text-align: center;
    }
    .mobile-align-#{$rdirection} {
      text-align: $rdirection;
    }
  }
  .content-block__content {
    &.vertical-alignment--bare {
      align-items: center;
      justify-content: normal;
    }
    &.horizontal-alignment--bare {
      justify-content: center;
      align-items: initial;
    }
  }
  .content-block__picture-wrapper {
    margin: auto;
  }
  .content-block--content .content-block--text {
    height: auto;
  }
  @include breakpoint($tablet-down) {
    .text-alignment {
      text-align: center;
    }
    .mobile-position-image-text,
    .mobile-position-text-image {
      display: flex;
      flex-direction: column;
    }
    .mobile-position-text-image {
      .content-block__text-wrapper {
        order: 1;
      }
      .content-block__picture {
        order: 2;
      }
    }
  }
  @include breakpoint($medium-up) {
    .content-block__inner {
      display: flex;
    }
    .content-block__picture,
    .content-block__text-wrapper {
      width: 50%;
      height: auto;
      margin: 0;
    }
    .position-text-image {
      .content-block__picture {
        order: 2;
      }
    }
  }
  .content-block__responsive-text {
    display: none;
  }
  .content-block__line--header {
    word-wrap: inherit;
  }
  .content-block--text {
    overflow: visible;
  }
  .padding--bare {
    padding: 1rem 1rem 2rem; // iphone SE
    @include breakpoint($iphone-6-up) {
      padding: 2rem 1.25rem 3rem;
    }
  }
  .empty-container {
    padding: 0;
    height: 0;
  }
}

.content-block--staggered {
  .content-block__inner {
    @include breakpoint($medium-up) {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  .alignment--bare {
    @include breakpoint($medium-up) {
      text-align: #{$ldirection};
    }
  }
  .content-block__inner-column {
    &:last-child {
      @include breakpoint($medium-down) {
        padding: 1rem 1.25rem 3rem;
        .content-block__picture {
          display: none;
        }
      }
      @include breakpoint($medium-up) {
        padding: 20vh 0 0;
      }
      .content-block__text {
        @include breakpoint($medium-up) {
          margin-bottom: 20px;
        }
      }
      .content-block__picture-wrapper {
        @include breakpoint($medium-up) {
          float: #{$rdirection};
        }
      }
    }
    @include breakpoint($medium-up) {
      width: 45%;
      max-width: 617px;
    }
    .content-block__content {
      @include breakpoint($medium-down) {
        align-items: center;
      }
      .content-block__text {
        @include breakpoint($medium-down) {
          text-align: center;
        }
      }
    }
    .content-block__picture-wrapper {
      margin: 0 auto;
      @include breakpoint($medium-up) {
        margin: unset;
      }
    }
  }
  .content-block__line--content {
    margin: 1rem auto;
  }
  .content-block {
    margin: unset;
  }
  .content-block__picture {
    @include breakpoint($medium-up) {
      max-height: 853px;
    }
  }
}

.content-block--mpp-tout,
.content-block--hero {
  max-width: $max-width-xlarge;
  margin: auto;
  .content-block__line--header {
    word-wrap: inherit;
  }
  .content-block__text {
    overflow: visible;
  }
  .mantle-media-asset {
    height: 100%;
    img,
    video {
      height: 100%;
    }
  }
}

.content-block--mpp-tout {
  @include breakpoint($medium-down) {
    .position--bare,
    .position-image,
    .position-text {
      @include contentBlockTextHeight;
    }
    .position-image {
      .content-block__content-wrapper {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
      }
      .content-block__picture {
        position: relative;
      }
    }
  }
  .justify-end {
    .padding--bare {
      @include breakpoint($landscape-up) {
        margin-bottom: 40px;
        @if $cr22 == true {
          margin-bottom: 0;
        }
      }
    }
  }
}

.content-block--hero {
  .padding--bare {
    @include breakpoint($landscape-up) {
      padding: 2.75rem 2.75rem 3rem;
    }
  }
  @include breakpoint($medium-down) {
    .position--bare,
    .position-image,
    .position-text {
      @include contentBlockImageHeight;
    }
  }
}

.style--bare {
  .content-block__line--header & {
    @include t2;
    font-size: 36px;
    @include breakpoint($landscape-up) {
      font-size: 60px;
    }
    margin-#{$ldirection}: -3px;
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: -5px;
    }
    @include breakpoint($landscape-up) {
      margin-#{$ldirection}: -8px;
      #bestseller-results & {
        text-align: center;
      }
    }
    p {
      line-height: inherit;
    }
  }
  .content-block__line--eyebrow & {
    font-size: 13px;
    letter-spacing: 1px;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
    @include breakpoint($large-up) {
      font-size: 15px;
    }
  }
  .content-block__line--content & {
    font-size: 15px;
    @include breakpoint($large-up) {
      font-size: 16px;
    }
  }
}

.padding--bare {
  padding: 2rem 20px 3rem;
  @include breakpoint($iphone-6-up) {
    padding: 2rem 1.25rem 3rem;
  }
  @include breakpoint($landscape-up) {
    padding: 2.5rem 2.5rem 3rem;
  }
}

// Text always stacks on top of white background on mobile
.content-block--hero,
.content-block--mpp-header {
  @include breakpoint($medium-down) {
    .no-main-image .no-background .content-block--text.color-white {
      color: $color-black;
    }
  }
}

// MPP Tout aligns by image always
.content-block--mpp-tout.no-background {
  @include breakpoint($medium-down) {
    .no-background .position-text .content-block--text.color-white {
      color: $color-black;
    }
  }
}
