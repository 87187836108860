///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules.
///

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input placeholders
///
@mixin input-styled-check($icon, $suffix: '_checked', $padding: 5px) {
  // hide the actual element
  position: absolute;
  #{$ldirection}: -9999px;
  ~ label,
  ~ .label {
    @include icon($icon);
    &::before {
      margin-#{$rdirection}: $padding;
    }
  }
  &:checked {
    ~ label,
    ~ .label {
      @include icon($icon + $suffix);
    }
  }
}
// Addendum --
// Expected HTML:
// Either this:
// <input type="checkbox" id="thecheckbox">
// <label for="thecheckbox">Label Text</label>
//
// Or this:
// <label>
//   <input type="checkbox">
//   <span class="label">Label Text</span>
// </label>

///
/// Retina Images
///
/// Usage:
/// @include image-2x('logo2x.png', 100px, 25px);
/// Note: Retina images should follow naming convention of 'image@2x.png'
///
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: image-url($image);
    background-size: $width $height;
  }
}

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

///
/// Circles
///
/// Usage:
/// @include circle(200px, #999);
///
@mixin circle($default-circle-width: 41px, $color: #ccc) {
  width: $default-circle-width;
  height: $default-circle-width;
  background: $color;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

///
/// Triangles
/// Add "position: relative" for the parent element.
///
/// Usage:
/// @include css-arrow(position, cut, color, outline color, size);
///
/// Examples:
/// @include css-arrow(bottom, null, #000, null, 20px); // Use "null" if not cut or outline
/// @include css-arrow(bottom, $rdirection, #000, #999, 20px); // Use "null" if not cut or outline
///
@mixin arrow($arrow-direction, $arrow-cut, $arrow-color, $arrow-color-outline, $arrow-size) {
  $arrow-direction-opposite: nth(
    (top, $rdirection, bottom, $ldirection),
    index((bottom, $ldirection, top, $rdirection), $arrow-direction)
  );
  &:after {
    position: absolute;
    content: ' ';
    top: auto;
    #{$ldirection}: auto;
    height: 0;
    width: 0;
    margin: 0;
    border: solid $arrow-size transparent;
    pointer-events: none;
  }
  &:after {
    border-#{$arrow-direction}: none;
    border-#{$arrow-direction-opposite}: solid $arrow-size $arrow-color;
    margin-#{$ldirection}: (-$arrow-size);
    #{$ldirection}: 50%;
  }
  @if $arrow-cut != null {
    &:after {
      border-#{$arrow-cut}: $arrow-size / 2;
    }
  }
  @if $arrow-color-outline != null {
    &:before {
      position: absolute;
      content: ' ';
      top: auto;
      #{$ldirection}: auto;
      height: 0;
      width: 0;
      @include swap_direction(margin, 0);
      border: solid ($arrow-size + 1px) transparent;
      pointer-events: none;
      border-#{$arrow-direction}: none;
      border-#{$arrow-direction-opposite}: solid ($arrow-size + 1px) $arrow-color-outline;
      margin-#{$ldirection}: -($arrow-size + 1px);
      #{$ldirection}: 50%;
    }
  }
  @if $arrow-cut != null and $arrow-color-outline != null {
    &:before {
      border: solid ($arrow-size + 2px) transparent;
      border-#{$arrow-direction-opposite}: solid ($arrow-size + 2px) $arrow-color-outline;
    }
    &:after,
    &:before {
      border-#{$arrow-cut}: $arrow-size / 2;
    }
  }
}

///
/// Vertical centerer
/// Source: http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
///
@mixin vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

%vertical-center {
  @include vertical-center;
}

@mixin colorbox-fixed-overlay($max-width, $margin: 50px) {
  width: 100vw !important;
  height: 100vh !important;
  left: 0px !important;
  @include breakpoint($medium-down) {
    top: 0px !important;
    // ios is buggy with fixed positioning
    position: absolute !important;
  }
  #cboxWrapper {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: auto !important;
    @include breakpoint($medium-up) {
      max-width: calc(100vw - (#{$margin} * 2));
      margin-top: $margin;
      margin-bottom: $margin;
    }
    @include breakpoint($max-width + $margin * 2) {
      max-width: $max-width;
    }
  }
  #cboxContent {
    box-sizing: border-box;
    height: auto !important;
    padding: 0px;
    @include breakpoint($medium-up) {
      max-width: calc(100vw - (#{$margin} * 2));
    }
    @include breakpoint($max-width + $margin * 2) {
      max-width: $max-width;
    }
    @include breakpoint($medium-down) {
      padding: 0px;
      width: 100vw !important;
    }
  }
  #cboxLoadedContent {
    height: auto !important;
    width: auto !important;
    @include breakpoint($medium-down) {
      padding: 0px;
      width: 100vw !important;
    }
  }
}

///
/// Hide from both screenreaders and browsers: h5bp.com/u
///
@mixin hidden {
  display: none !important;
  visibility: hidden;
}

@mixin buttonReset {
  background: transparent;
  -webkit-font-smoothing: inherit;
  -webkit-appearance: none;
}

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

@mixin contentBlockImageHeight {
  .content-block__picture {
    position: relative;
    z-index: 1;
    height: 100%;
    img,
    video {
      display: block;
      object-fit: cover;
    }
  }
  .content-block__content-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 0;
    left: 0;
  }
}

@mixin contentBlockTextHeight {
  .content-block__picture {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    img,
    video {
      display: block;
      object-fit: cover;
      width: 100%;
      min-height: 100%;
      max-height: none;
      height: auto;
    }
  }
  .content-block__content-wrapper {
    position: relative;
    z-index: 3;
    height: 100%;
  }
}

@mixin flexGrid {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
}

@mixin flexGridColumns {
  @include breakpoint($medium-down) {
    .flex-sm-cols--auto {
      flex: 1 1 auto;
    }
    .flex-sm-cols--2 {
      width: 50%;
    }
    .flex-sm-cols--3 {
      width: 33%;
    }
    .flex-sm-cols--4 {
      width: 25%;
    }
    .flex-sm-1 {
      flex: 1;
    }
  }
  @include breakpoint($medium-up) {
    .flex-lg-cols--auto {
      flex: 1 1 auto;
    }
    .flex-lg-cols--2 {
      width: 50%;
    }
    .flex-lg-cols--3 {
      width: 33%;
    }
    .flex-lg-cols--4 {
      width: 25%;
    }
    .flex-lg-1 {
      flex: 1;
    }
  }
}

// photoshop tracking
@mixin tracking($tracking) {
  letter-spacing: ($tracking / 1000) * 1em;
}

// 2019 Redesign

@mixin redesign-text-style {
  font-family: $body-font;
  font-size: 15px;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: $color-black-2;
}

@mixin redesign-text-style-bold {
  @include redesign-text-style;
  font-family: $body-font-bold;
}

@mixin mpp-controls-text-style {
  @include redesign-text-style;
}

@mixin store-inventory-check-text-style {
  @include mpp-controls-text-style;
}

@mixin mpp-controls-text-style-bold {
  @include mpp-controls-text-style;
  font-family: $body-font-bold;
  text-transform: uppercase;
}

@mixin ff-view-all-link {
  text-transform: uppercase;
  font-family: $body-font;
  font-size: 12px;
  @include tracking(100);
  color: $color-gray-link;
}

@mixin ff-button {
  display: flex;
  align-items: center;
  justify-content: center;
  @include tracking(50);
  text-transform: uppercase;
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: $body-font-bold;
  height: 40px;
  font-size: 11px;
  @include breakpoint($medium-up) {
    font-size: 12px;
  }
}

@mixin ie11-only {
  @media all and (-ms-high-contrast: none) {
    @content;
  }
}

@mixin wrap {
  max-width: $max-width;
  padding: 0 20px;
  position: relative;
  margin: 0 auto;
  @include breakpoint($large-up) {
    padding: 0 16px;
  }
  &.wrap--no-padding {
    @include swap_direction(padding, 0);
  }
}

@mixin ajaxSpinner($color, $width, $height, $borderwidth) {
  width: $width;
  height: $height;
  border: $borderwidth solid $color;
  border-#{$rdirection}-color: transparent;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  -ms-animation: spin 1s linear infinite;
  -o-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

// Adaptive placeholder defaults
// ------------------------------
// PRIVATE - default settings for adaptive placeholder

$ap-default: (
  height: 3em,
  margin: 1em,
  border: 1px,
  border-radius: 0px,
  font-size: 1em,
  border-color: #cccccc,
  label-color: #999999,
  active-color: #999999,
  valid-color: #999999,
  invalid-color: #ff9999,
  placeholder-background-color: white,
  textarea: false
);

// Default User Placeholder Settings
// ------------------------------
// - Allows $ap-default to be overwritten by default user settings

$adaptive-placeholder: ();

$textarea-placeholder: ();

// adaptive-placeholder
// --------------------
// - Creates adaptive placeholder

@mixin adaptive-placeholder($map: $adaptive-placeholder) {
  // Only overwrite ap defaults if $map is $adaptive-placeholder
  @if $map == $adaptive-placeholder {
    $ap-default: map-merge($ap-default, $map) !global;
    $map: $ap-default;
  }
  // Updates default map with new values
  $map: map-merge($ap-default, $map);
  // Gets variables
  $height: map-get($map, height);
  $margin: map-get($map, margin);
  $border: map-get($map, border);
  $radius: map-get($map, border-radius);
  $font-size: map-get($map, font-size);
  //$border-color: map-get($map, border-color);
  $label-color: map-get($map, label-color);
  $active-color: map-get($map, active-color);
  $valid-color: map-get($map, valid-color);
  $invalid-color: map-get($map, invalid-color);
  $placeholder-background: map-get($map, placeholder-background-color);
  $isTextarea: map-get($map, textarea);
  $borders: $border * 2;

  // Warnings for best practices
  @if $height < 2 * $margin {
    @warn "height needs to be at least 2x margin";
  }
  @if $height < $font-size {
    @warn "height needs to be at least >= font-size";
  }

  // Input / Textarea properties
  height: $height;
  height: calc(#{$height} - -#{$borders});
  font-size: $font-size;
  border-radius: $radius;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-box-shadow: none;
  box-shadow: none;
  resize: none;
  outline: none;
  &:focus,
  &:active {
    & + label:before {
      color: $active-color;
    }
  }
  // Placeholder Styles
  & + label {
    display: block;
    font-size: $font-size;
    span.label-content {
      display: none;
    }

    // Vertically align label with input
    @if $isTextarea {
      height: $height;
      height: calc(#{$height} - -#{$borders});
      line-height: 1;
      padding-top: $margin;
      padding-top: calc(#{$margin} - -#{$border});
    } @else {
      height: $height;
      line-height: $height;
    }

    // Offset label correctly with negative margins
    @if $isTextarea {
      margin-top: $height;
      margin-top: calc(-#{$height} - #{$borders});
    } @else {
      margin-top: $height;
      margin-top: calc(-#{$height} - #{$border});
    }
    cursor: text;
    &:before {
      // Initial Text
      content: attr(placeholder);
      display: inline-block;
      color: $label-color;
      margin: 0 $margin;
      margin: 0 calc(#{$margin} - -#{$borders});
      white-space: nowrap;
      // Transformation properties
      -webkit-transition-property: -webkit-transform, color;
      transition-property: transform, color;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      -webkit-transition-delay: 0;
      transition-delay: 0;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      // Set origin to left center so it scales to the left instead of center center
      -webkit-transform-origin: left center;
      -ms-transform-origin: left center;
      transform-origin: left center;

      // Heres a version without prefixes
      // transition-property: transform, color;
      // transition-duration: 0.2s;
      // transition-delay: 0;
      // transition-timing-function: ease-out;
      // // Set origin to left center so it scales to the left instead of center center
      // transform-origin: left center;
    }
  }

  // Focused, active and valid states for input and placeholder
  // Shifts up and adds a background white
  &:focus,
  &.active,
  &.js-label-mode {
    & + label:before {
      background: $placeholder-background;
      // set padding for left & right of tiny placeholder text
      line-height: 1;
      padding: 0 $border * 1.5;
      // Adjusted all calcs within translates according to to split up into various translateYs instead of using calc for IE 10 support
      // http://stackoverflow.com/questions/21469350/not-possible-to-use-css-calc-with-transformtranlatex-in-ie
      @if $isTextarea {
        // transform: translateY(calc((-#{$margin} - 0.5em) - #{$border * 0.5})) scale(0.8, 0.8);
        -webkit-transform: translateY(-$margin - 0.5em) translateY(-$border * 0.5) scale(0.8, 0.8);
        transform: translateY(-$margin - 0.5em) translateY(-$border * 0.5) scale(0.8, 0.8);
      } @else {
        -webkit-transform: translateY(-$height * 0.5) translateY(-$border * 0.5) scale(0.8, 0.8);
        transform: translateY(-$height * 0.5) translateY(-$border * 0.5) scale(0.8, 0.8);
      }
    }
  }
  // Change placeholder value once text is entered.
  &.js-label-mode {
    & + label:before {
      content: attr(alt);
    }
  }
  &:required {
    &.error,
    &:invalid {
      & + label:before {
        color: $label-color;
      }
      &:empty,
      &[value=''],
      &:not(:checked),
      &.empty {
        & + label:before {
          color: $label-color;
        }

        // for some reason, if I group &.error, &.touched, and &.checkedEmpty, magic_js chokes on the CSS linking
        &.error,
        &.touched {
          & + label:before {
            color: $label-color;
          }
        }
        &.checkedEmpty {
          & + label:before {
            color: $label-color;
          }
        }
        &:focus {
          & + label:before {
            color: $label-color;
          }
        }
      }
    }
    &:valid {
      & + label:before {
        color: $label-color;
      }
      &.error {
        & + label:before {
          color: $label-color;
        }
      }
    }
    &[data-error] {
      & + label:before {
        color: $label-color;
      }
    }
  }
  &:focus {
    & + label:before {
      color: $active-color;
    }
  }
}

@mixin loading {
  @include background(image-url('/loading.gif') no-repeat center center $color-white);
  @include opacity(0);
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  &.fade-in {
    @include opacity(0.8);
  }
  .text {
    display: none;
  }
}

//checkout mixin
@mixin checkout-sidebar-panel {
  background: $color-gray-background;
  @include swap_direction(padding, 16px 20px);
}

@mixin checkout-header {
  @include h24;
  text-indent: 0;
  @include swap_direction(margin, 4px 0 10px 0);
  padding-bottom: 17px;
  border-bottom: 1px solid $color-black;
}

@mixin checkout-section-header {
  @include h24;
  text-indent: $checkout-spacing;
  @include swap_direction(margin, 0);
  padding-bottom: 0;
  line-height: 50px;
  background: $color-gray-background;
  border-bottom: 1px solid $color-gray-border;
  .active + .collapsed &, // closed > open
  .finished + .collapsed &, // closed > open
  .finished + .active &, // open > open
  .finished + .finished &, // open > open
  .finished + .hidden + .collapsed &, // open > hidden > open
  .finished + .disabled + .collapsed &, // open > hidden > open
  .active + .disabled + .disabled + .collapsed &, // open > hidden > hidden > open
  .active + .hidden  + .hidden + .collapsed &, // open > hidden > hidden > open
  .active + .disabled + .hidden + .collapsed &, // open > hidden > hidden > open
  .active + .hidden  + .disabled + .collapsed &, // open > hidden > hidden > open
  .finished + .disabled + .disabled + .collapsed &, // open > hidden > hidden > open
  .finished + .hidden + .hidden + .collapsed &, // open > hidden > hidden > open
  .finished + .disabled + .hidden + .collapsed &, // open > hidden > hidden > open
  .finished + .hidden + .disabled + .collapsed & {
    // open > hidden > hidden > open
    border-top: 1px solid $color-gray-border;
  }
  .collapsed + .collapsed &, // closed > closed
  .collapsed + .disabled + .collapsed &, // closed > hidden > closed
  .collapsed + .hidden + .collapsed &, // closed > hidden > closed
  .collapsed + .disabled + .disabled + .collapsed &, // closed > hidden > hidden > closed
  .collapsed + .hidden + .hidden + .collapsed &, // closed > hidden > hidden > closed
  .collapsed + .disabled + .hidden + .collapsed &, // closed > hidden > hidden > closed
  .collapsed + .hidden + .disabled + .collapsed & {
    // closed > hidden > hidden > closed
    border-top: none;
  }
  a {
    @include link-reset;
  }
  .collapsed & {
    color: $color-beige-dark;
  }
}

@mixin select-arrow--down {
  padding: 0;
  line-height: 38px;
  font-size: 10px;
  text-align: center;
  &:hover {
    color: $color-black;
  }
  @include icon('arrow_right', 'before', false, false, 20px);
  &:before {
    line-height: 40px;
    #{$rdirection}: 10px;
    transform: rotate(90deg);
  }
}

@mixin disable-button() {
  background-color: $disabled-color;
  cursor: $disabled-cursor;
}

@mixin viewcart-panel {
  .viewcart-buttons-panel {
    .continue-buttons {
      .disabled {
        &.continue-checkout {
          @include disable-button();
        }
      }
    }
  }
}

// 2019 Redesign.
// Styles for single product appearing inside content blocks.
// Contains overrides to product_brief_v2 styles where needed.
@mixin single-product {
  @include breakpoint($medium-up) {
    display: flex;
  }
  .product-brief {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    &.IE-min-height-processed {
      min-height: auto;
    }
  }
  .product-brief__add-to-bag {
    float: none;
    width: 100%;
  }
  .product-brief__image-container {
    width: 30%;
    @include breakpoint($medium-up) {
      order: 2;
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }
  .product-brief__product-info-wrapper {
    width: 70%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: auto;
    @include breakpoint($medium-up) {
      width: 50%;
      order: 1;
    }
  }
  .product-brief__shade-info {
    display: block;
  }
  .product-brief__shade-name {
    display: inline;
  }
  .product-brief__image-wrapper {
    img {
      max-width: 160px;
      width: 100%;
    }
  }
  .product-brief-shades__grid,
  .product-brief-shades__badge {
    display: none;
  }
  .product-brief__abstract,
  .product-brief__header,
  .product-brief__price,
  .product-brief__short-desc,
  .product-brief__add-to-bag,
  .product-brief__shade-info {
    text-align: #{$ldirection};
  }
  .product-brief__abstract {
    display: flex;
    flex-direction: column;
    order: 1;
    margin-bottom: 0;
    flex: 1;
    @include breakpoint($medium-up) {
      flex: none;
    }
  }
  .product-brief__headline {
    order: 1;
  }
  .product-brief__short-desc {
    order: 2;
    margin: 6px 0;
  }
  .product-brief__price {
    order: 3;
    margin-bottom: 0;
  }
  .product-brief__headline-review-link {
    display: none;
  }
  .product-brief-shades__title {
    text-align: #{$ldirection};
  }
  .product-brief__shades {
    padding: 0;
    order: 2;
  }
  .product-brief-shades__header {
    padding-#{$ldirection}: 0;
  }
  .product-brief__bottom {
    order: 3;
  }
  .product-brief-shades {
    padding: 0;
  }
  .product-brief__header {
    margin-top: 0;
  }
  .product-brief__bottom-mobile {
    display: flex;
    flex-direction: row;
    height: 100%;
    flex-wrap: wrap;
    @include breakpoint($medium-up) {
      text-align: #{$ldirection};
    }
    .product-brief__short-desc-link,
    .product-brief__add-to-bag {
      display: inline;
    }
    .product-brief__quick-shop-cta {
      & ~ .product-brief__add-to-bag {
        @include breakpoint($large-up) {
          display: none;
        }
      }
    }
    .product-brief__short-desc-link {
      width: auto;
      padding-#{$rdirection}: 5px;
      height: 35px;
      line-height: 35px;
    }
    .product-brief__inventory_status {
      order: 1;
    }
    .product-brief__short-desc-link {
      order: 3;
    }
    .product-brief__quickshop-buttons {
      order: 2;
      .product-brief__quick-shop-cta {
        min-width: 224px;
      }
    }
    .product-brief__add-to-bag {
      order: 2;
      width: auto;
      @include breakpoint($medium-up) {
        width: 100%;
      }
      .product__button--add-to-bag {
        width: auto;
        height: auto;
        line-height: 1;
      }
    }
  }
}
@mixin mobile-hidden {
  display: none !important;
  @include breakpoint($medium-up) {
    display: block !important;
  }
}

@mixin address-form-header {
  @include h24;
  margin-bottom: 10px;
  text-align: $ldirection;
}

@mixin address-book-page {
  @include clearfix;
  .account-utilities {
    float: $ldirection;
    margin-#{$rdirection}: 32px;
  }
  .address-book-page {
    &__content {
      width: auto;
      overflow: hidden;
    }
    &__header {
      @include breakpoint($small-down) {
        @include h13;
      }
      @include breakpoint($medium-up) {
        @include h19;
        text-indent: $account-indent;
        margin: 4px 0 17px;
        padding-bottom: 16px;
        border-bottom: 2px solid $color-black;
      }
    }
  }
  //shared
  .address-book,
  .payment-info {
    @include swap_direction(margin, 0 0 40px $account-indent);
    width: 95.43%;
  }
  .address-book .section-head,
  .payment-info .payment-book__header {
    margin-bottom: 6px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
  .address-book__header,
  .payment-info__header {
    @include h8;
    margin: 0 0 10px;
    padding-bottom: 14px;
    border-bottom: $account-alt-heading-separator;
    font-family: $body-font;
    font-size: 1.5em;
    letter-spacing: 0.15px;
  }
  .address-book__content,
  .payment-info__content {
    margin-bottom: 14px;
  }
  .address-item__address {
    margin-bottom: 14px;
  }
  .address-item__default-address,
  .payment-item__default-address {
    margin-top: 6px;
  }
  .address-item__edit,
  .address-item__delete,
  .payment-item__edit,
  .payment-item__delete {
    @include l5;
  }
  .address-item__delete,
  .payment-item__delete {
    margin-#{$ldirection}: 50px;
  }
  .default-address__button,
  .default-payment__button {
    @include button--secondary;
  }
  // addresses
  .address-book__link {
    @include button;
  }
  .address-item__billing-link {
    margin-top: 25px;
    margin: 20px 0;
    border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
    img {
      margin-#{$rdirection}: 38%;
      float: $ldirection;
      margin-top: 10px;
    }
  }
  .address-book__others {
    margin-top: 14px;
  }
  // payments
  .payment-info__link {
    @include button;
  }
  .payment-item__address,
  .payment-book__other {
    margin-top: 20px;
  }
  .payment-book__other {
    border-bottom: 1px solid $color-gray;
  }
  .payment-book-item__other {
    padding: 0 0 10px;
  }
  .payment-item__details {
    padding: 10px 0;
    border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
  }
  @if $og-enabled {
    .address-book-page__header {
      @include breakpoint($small-down) {
        @include h13;
        margin-top: 24px;
        font-size: 18px;
      }
    }
    .payment-info {
      @include swap_direction(margin, 0 0 40px $account-indent);
      width: 95.43%;
      @include breakpoint($small-down) {
        margin-#{$rdirection}: 0;
        margin-bottom: 0;
      }
    }
    .address-book .section-head,
    .payment-info .payment-book__header {
      @include breakpoint($small-down) {
        color: $color-pink;
      }
      @include breakpoint($medium-up) {
        font-weight: 600;
      }
    }
    .address-item .section-head,
    .payment-info .payment-book__header {
      @include breakpoint($small-down) {
        margin-bottom: 0;
        margin-top: 0;
        color: $color-pink;
        font-weight: 600;
      }
    }
    .address-item__details,
    .payment-item__details {
      @include breakpoint($small-down) {
        width: 83%;
        float: $ldirection;
        border-top: 0;
        border-bottom: 0;
      }
    }
    .address-item__controls,
    .payment-item__controls {
      @include breakpoint($small-down) {
        width: 17%;
        float: $ldirection;
      }
      a {
        @include breakpoint($small-down) {
          text-align: $rdirection;
          display: block;
          padding-bottom: 14px;
        }
      }
    }
    .address-book__link,
    .payment-info__link {
      @include button;
      @include breakpoint($small-down) {
        width: 80%;
        margin: 0 auto;
        display: block;
        padding: 18px 0;
      }
    }
    .address-info__header {
      @include breakpoint($small-down) {
        border-top: 1px solid $color-black;
        border-bottom: 1px solid $color-gray;
        padding: 16px;
        margin-bottom: 0;
      }
    }
    .address-item {
      border-bottom: 1px solid $color-gray;
      padding: 14px 18px;
      @include breakpoint($medium-up) {
        width: 33.33%;
        float: $ldirection;
        border-bottom: 0;
        padding: 0;
      }
    }
    .address-item__address {
      @include breakpoint($medium-up) {
        margin-bottom: 14px;
        height: 185px;
      }
    }
    .payment-info__default,
    .payment-info__other.payment {
      @include breakpoint($small-down) {
        border-bottom: 1px solid $color-gray;
        padding: 14px 18px;
      }
    }
    .payment-item__address {
      padding: 12px 16px 0;
      margin-top: 0;
      @include breakpoint($medium-up) {
        padding: 10px 0 0;
        margin-top: 20px;
      }
    }
    .payment-book__other {
      border-bottom: 1px solid $color-gray;
      margin-top: 20px;
      @include breakpoint($medium-up) {
        border-bottom: 0;
        margin-top: 0;
      }
    }
    .payment-book__default,
    .payment-book__other {
      @include breakpoint($medium-up) {
        width: 33.33%;
        float: $ldirection;
        height: 295px;
      }
      border-bottom: 0;
    }
    .payment-item__details {
      @include breakpoint($medium-up) {
        border-bottom: 1px solid $color-gray;
        margin-#{$rdirection}: 24px;
        padding: 10px 0;
      }
    }
    .payment-item__name {
      font-weight: 600;
    }
    .payment-item__details img {
      padding-top: 10px;
    }
    .payment-item__details .payment-details {
      @include breakpoint($small-down) {
        padding-#{$ldirection}: 12px;
      }
    }
    .payment-item__details img,
    .payment-item__details .payment-details {
      float: $ldirection;
      @include breakpoint($medium-up) {
        float: none;
      }
    }
    .payment-item__header {
      font-weight: 600;
      @include breakpoint($medium-up) {
        font-weight: 300;
      }
    }
  }
}

@mixin checkout-item-column {
  width: 100%;
  clear: both;
  // keep 100% until after ipad portrait
  @include breakpoint($medium-up) {
    float: $ldirection;
    clear: none;
  }
}

@mixin checkout-address {
  .ship-type {
    display: none;
  }
  .name-fields,
  .addresses,
  .city-state-zip,
  .country,
  .phones {
    label {
      display: none;
    }
  }
  margin-top: 18px;
  .address-to-use {
    margin-bottom: 20px;
    .radio {
      display: block;
      @include breakpoint($medium-up) {
        display: inline;
        margin-#{$rdirection}: 10px;
      }
    }
  }
  .form-item {
    &.state,
    &.select-menu {
      margin-bottom: 20px;
    }
    &.phone-2,
    &.address3 {
      @include hidden;
    }
    @include breakpoint($medium-up) {
      &.first-name {
        float: $ldirection;
        width: 48%;
      }
      &.last-name {
        float: $rdirection;
        width: 48%;
      }
      &.address1 {
        clear: both;
      }
      &.address2 {
        width: 100%;
      }
      &.city {
        float: $ldirection;
        margin-#{$ldirection}: 2.1%;
        width: 35%;
      }
      &.state {
        float: $rdirection;
        width: 38.44%;
      }
      &.postal-code {
        float: $ldirection;
        width: 22.44%;
      }
      &.phone-1 {
        float: $ldirection;
        width: 48%;
      }
    }
  }
  .city-state-zip {
    .state {
      @include breakpoint($medium-up) {
        label {
          display: none !important;
        }
      }
    }
  }
  .country {
    margin-bottom: 20px;
    &__note {
      margin-top: 4px;
      font-size: 11px;
      line-height: normal;
    }
    @include breakpoint($medium-up) {
      width: 48%;
    }
  }
  .phone-required {
    @include hidden;
    //margin-bottom: 16px;
  }
}

@mixin cvv {
  @include breakpoint($medium-up) {
    float: $ldirection;
    width: 106px;
    margin-#{$ldirection}: 40px;
  }
}

@mixin expires-month {
  @include breakpoint($medium-up) {
    float: $ldirection;
  }
}

@mixin payment-form {
  width: 100%;
  @include swap_direction(margin, 0);
  .card-number {
    @include breakpoint($medium-up) {
      width: 253px;
    }
  }
  .expires-month {
    @include expires-month;
  }
  .security-code {
    display: none;
  }
  .expires-date__container {
    margin-bottom: 18px;
    @include breakpoint($medium-up) {
      @include clearfix;
      margin-bottom: 0;
      width: 253px; //hard value for selectbox
      label {
        line-height: 19px;
      }
    }
    .expires-date__month {
      margin-bottom: $checkout-spacing;
      @include breakpoint($medium-up) {
        margin-bottom: 0;
        width: 106px;
        float: $ldirection;
      }
      .expiration_month {
        @include brandon-text;
      }
    }
    .expires-date__year {
      @include breakpoint($medium-up) {
        width: 106px;
        float: $ldirection;
        @include swap_direction(margin, 0 0 0 41px);
      }
      .expiration_year {
        @include brandon-text;
      }
    }
  }
  .cvv {
    @include cvv;
  }
}

@mixin payment-panel {
  @include clearfix;
  .messages {
    margin: 0 $checkout-spacing;
    list-style-type: none;
  }
  .payment-edit-content {
    position: relative;
    margin: 18px $checkout-spacing $checkout-spacing;
    &__title {
      @include hidden;
    }
    .payment-edit-content--notice-copy {
      margin-bottom: 20px;
    }
    .payment-edit-content--secure-logo {
      display: inline;
      width: 15%;
      float: $rdirection;
      margin-#{$ldirection}: 2%;
      // if the following isnt needed for unbeknownst security reasons, please remove it
      //.payment-edit-content__a--overlay {
      //   display: block;
      //}
    }
    .payment-option {
      float: none;
      width: 83%;
    }
    .field-are-required {
      @include hidden;
    }
  }
  .choose-payment-method,
  .payment-information {
    display: none;
  }

  // The credit card and paypal radios
  .payment-type {
    margin-bottom: 18px;
  }
  .payment-option,
  .payment-control {
    @include clearfix;
    display: block;
    width: 100%;
    margin-bottom: 18px;
    &.radio {
      margin-bottom: 0;
      float: $ldirection; //note, a .payment-option unfloat was needed, and defined above near .secure-logo, for sm devices
    }
    &.radio:first-child {
      @include breakpoint($medium-up) {
        width: 60%;
      }
    }
    &.radio:last-child {
      @include breakpoint($medium-up) {
        width: 40%;
      }
    }
    @include breakpoint($medium-up) {
      @include inline-block;
      &.radio:first-child {
        width: 53.6%;
      }
      &.radio:last-child {
        width: 45%;
      }
    }
    // input,
    label {
      text-transform: uppercase;
    }
    .related-media {
      display: block;
      margin-top: 10px;
      @include breakpoint($large-up) {
        margin-top: 20px;
      }
      img {
        margin-#{$rdirection}: 15px;
        display: inline-block;
      }
    }
    .related-media.cc {
      margin-bottom: 20px;
      @include breakpoint($medium-up) {
        margin-bottom: 0;
      }
    }
    .related-media.pp {
      margin-bottom: 0;
    }
  }
  .payment_method-container {
    margin-bottom: 18px;
  }
  .saved-payments {
    margin: 18px 0;
    .cc-info {
      margin-top: 18px;
    }
  }
  .payment-form {
    @include payment-form;
  }
  .ar-checkout-cc-message {
    padding: 0 20px 12px;
    @include breakpoint($medium-up) {
      padding: 0 0 12px;
    }
  }
  .save-options {
    clear: $ldirection;
  }
  .payment-container {
    .checkout__subtitle {
      margin-bottom: 18px;
    }
    .address-to-use__container {
      margin-bottom: 18px;
      .address-to-use.radio {
        display: block;
        @include breakpoint($medium-up) {
          @include inline-block;
          &:first-child {
            width: 53.6%;
          }
          &:last-child {
            width: 45%;
          }
        }
      }
      label {
        text-transform: uppercase;
      }
    }
  }
  .new-address {
    @include checkout-address;
    &.add {
      margin-bottom: 10px;
    }
  }
  .existing-address {
    //display: none;
    margin-bottom: $checkout-spacing;
    .select-menu {
      margin: 20px 0;
    }
  }
  .select-address {
    margin-bottom: 20px;
    .address-to-use {
      @include breakpoint($small-down) {
        display: block;
      }
      @include breakpoint($medium-up) {
        &.radio {
          margin-#{$rdirection}: 10px;
        }
      }
    }
  }
  .shipping-address {
    margin-bottom: 30px;
  }

  ///
  ///  Giftcards
  ///
  .payment-giftcard {
    margin-bottom: $checkout-spacing;
  }
  .has-giftcard {
    margin-bottom: $checkout-spacing;
  }
  .checkout_giftcard {
    label {
      display: none;
    }
    @include breakpoint($medium-up) {
      .giftcard__number {
        float: $ldirection;
        width: 46.3%;
      }
      .giftcard__pin {
        float: $rdirection;
        width: 46.3%;
      }
    }
  }
}

@mixin account-layout {
  @include clearfix;
  max-width: $max-width;
  margin: 40px auto;
}

// Gnav Link Style - 2022
@mixin gnav-main-link {
  color: $color-white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.89;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.03em;
  @include breakpoint($landscape-only) {
    color: $color-core-black;
    display: inline-flex;
    height: auto;
    line-height: 1.5;
    letter-spacing: 0;
    padding: 0;
  }
  &:focus,
  &:hover {
    color: $color-core-black;
    text-decoration: none;
  }
}
@mixin gnav-category-link {
  font-family: $bb-sans;
  font-size: 30px;
  line-height: get-line-height(30px, 34px);
  letter-spacing: $gnav-category-link-letter-spacing;
  color: $color-white-op50;
  text-decoration: none;
  @include breakpoint($landscape-up) {
    font-size: 28px;
    line-height: 1;
  }
  @include breakpoint($large-up) {
    font-size: 30px;
    line-height: get-line-height(30px, 34px);
  }
  &:focus,
  &:hover {
    color: $color-white;
    text-decoration: none;
  }
}
@mixin gnav-text-link {
  font-family: $bbtext-regular;
  color: $color-white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.89;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0;
  @include breakpoint($landscape-only) {
    color: $color-white-op50;
    display: inline-flex;
    height: auto;
  }
  &:focus,
  &:hover {
    color: $color-white;
    text-decoration: none;
  }
}
@mixin gnav-section-title {
  font-family: $bbtext-regular;
  font-size: 15px;
  line-height: get-line-height(15px, 22px);
  color: $color-white-op50;
  @include breakpoint($landscape-only) {
    font-size: 14px;
    line-height: get-line-height(15px, 19px);
  }
}

// Gnav Link Style - 2022
@mixin gnav-mobile-back {
  cursor: pointer;
  position: fixed;
  top: $offerbar-mobile-height;
  #{$ldirection}: $container-pad-small;
  text-decoration: none;
  font-weight: 700;
  background-color: $color-white;
  height: $navbar-mobile-height - 1;
  line-height: 1;
  min-width: 148px;
  @include breakpoint($landscape-up) {
    top: $offerbar-height;
    height: $navbar-height - 1;
  }
  .site-header-formatter--sticky &,
  .gnav-offers-hidden & {
    top: 0;
  }
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

// Outline style on focused elements for keyboard accessibility
@mixin outline {
  outline: 1px solid $color-focus !important;
}

@mixin input-primary {
  @include typography-link-text;
  height: auto;
  padding: 15px 0;
  text-transform: none;
  border: none;
  border-bottom: 1px solid $color-core-black;
  color: $color-core-black;
}

// Triangle - creates right triangles with border-clipping tricks.
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

// SPP Text Style - 2022
@mixin spp-text {
  @include typography-link-text;
  color: $color-core-black;
}

// checkout related mixins

// secondary header/title style
@mixin checkout-secondary-heading {
  font-family: $base-font-family;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(30px, 32px);
}

// sub header/title style - medium version
@mixin checkout-sub-heading--medium {
  font-family: $base-font-family;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(18px, 24px);
}

// sub header/title style - small version - style 1
@mixin checkout-sub-heading--small {
  font-family: $base-font-family;
  font-size: 12px;
  line-height: get-line-height(12px, 18px);
}

// default styling for paragraphs/body text
@mixin checkout-body-text--medium {
  font-family: $base-font-family;
  font-size: 14px;
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for smaller paragraphs/body text
@mixin checkout-body-text--small {
  font-family: $base-font-family;
  font-size: 12px;
  line-height: get-line-height(12px, 14px);
}
